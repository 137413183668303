<template>
    <div id="testee">
        <div class="common-header">
            <div class="new-h-l-wrap">
                <i class="el-icon-arrow-left visit-back" @click="onGoBack"></i>
                <span class="testee-header">{{researchName}}</span>
                <el-tag effect="dark" type='danger' size="middle" v-if="envname">{{envname}}</el-tag>
                <p class="h-sub-wrap" @click="onChangeVersion">
                    <span class="header-sub-title">版本{{currentVersionNum||''}}</span><i class="el-icon-edit"></i>
                </p>
            </div>
            <ul class="testee-menu">
                <li v-for="(item,index) in menuList" :key="index" @click="addClass(index)"
                    :class="{'testee-menu_focu':index === current}">
                     <el-badge :value="item.label === '招募中心'?value:''" class="item" v-show="formatterLabel(item.label)">
                        <router-link replace
                                    :to="{path:item.to,query:{wx:$route.query.wx,recruit:$route.query.recruit,researchCode: researchCode, researchName: researchName,environment:environment,env:env}}">
                            {{item.label}}
                        </router-link>
                    </el-badge>
                </li>
                <li>
                    <el-popover placement="bottom" width="400" trigger="click" popper-class="morePopper">
                        <div v-for="(item,index) in more" :key="index">
                            <p class="more-title">{{item.title}}</p>
                            <ul>
                                <li v-for="(listItem,listIndex) in item.list" :key="listIndex"
                                    @click="listItemtemClick(listItem)">
                                    <i class="el-icon-house"></i>{{listItem.label}}
                                </li>
                            </ul>
                        </div>
                        <span slot="reference" style="cursor: pointer">更多
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
                    </el-popover>
                </li>
            </ul>
        </div>
        <router-view></router-view>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
            <span>{{info}}</span>
            <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="addExportExcelTask">确 定</el-button>
    </span>
        </el-dialog>
        <el-dialog title="更改版本" :visible.sync="vdialogVisible" width="40%">
            <div class="dia-v-wrap">
                <el-radio-group v-model="selectVersionCode">
                    <el-radio :label="item.versionCode" v-for="item in versionData.versionList" :key="item.id">
                        <div class="db-row-wrap">
                            <span style="flex:2;" >{{item.versionNum}}</span>
                            <span style="flex:3;" class="dia-v-span">发布于 {{item.createDate}}</span>
                            <span style="flex:3;" class="dia-v-span">备注：{{item.remark}}</span>
                        </div>
                    </el-radio>
                </el-radio-group>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="vdialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="onSelectVersion">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
    import Vue from 'vue'
    import {
        addExportTask
    } from "@/api/excelTask";
    import globalConst from '../utils/globalConst'
    import {
        localData
    } from "../utils/storage"
    import {
        authorContainer
    } from '../utils/storage'
    import {
        getMenuByResearch,
        getValue
    } from "@/api/testee";
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex';

    export default {
        name: 'Testee',
        data() {
            return {
                wx:0,
                recruit:0,
                envname: '',
                vdialogVisible: false,
                queryValue:'',
                researchName: decodeURIComponent(this.$route.query.researchName),
                researchCode: this.$route.query.researchCode,
                env: this.$route.query.env,
                environment: this.$route.query.environment,
                dialogVisible: false,
                info: "",
                title: "",
                current: 1,
                menuList: [
                    {
                        label: '招募中心',
                        to: 'recruitment'
                    },
                    {
                        label: '主页',
                        to: 'view'
                    },
                    {
                        label: '受试者列表',
                        to: 'detail'
                    },
                    {
                        label: '质疑管理',
                        to: 'query'
                    },
                    // {label: '稽查痕迹', to: 'trace'},
                    {
                        label: '任务列表',
                        to: 'excelTask'
                    },
                ],
                more: [{
                    title: '提交数据',
                    list: [{
                        label: '受试者列表',
                        value: '1'
                    },
                        {
                            label: '计划访视',
                            value: '2'
                        },
                        {
                            label: '添加受试者',
                            value: ''
                        },
                        {
                            label: '查看访视',
                            value: '4'
                        },
                        {
                            label: '质疑管理',
                            value: '5'
                        },

                        {
                            label: '数据导入',
                            value: '6'
                        }
                    ]
                },
                    {
                        title: '管理数据',
                        list: [{
                            label: '稽查痕迹',
                            value: '1'
                        },
                            {
                                label: '中心管理',
                                value: 'Manage'
                            },
                            {
                                label: '验证规则',
                                value: ''
                            },
                            {
                                label: 'CRF管理',
                                value: '4'
                            },
                            {
                                label: '查看研究',
                                value: '5'
                            },

                            {
                                label: '源数据验证',
                                value: '6'
                            }
                        ]
                    },
                    {
                        title: '导出数据',
                        list: [{
                            label: '创建数据集',
                            value: '1'
                        },
                            {
                                label: '导出数据集',
                                value: '2'
                            },
                            {
                                label: '导出CRF',
                                value: '3'
                            },
                            {
                                label: '导出报告',
                                value: '4'
                            },

                        ]
                    },
                    {
                        title: '系统日志',
                        list: [
                            {
                                label: '操作日志',
                                // value: '1',
                                value: 'SystemLog',
                                query: {
                                    researchCode: this.$localData('get', this.$gconst.RESEARCH_CODE)
                                }
                            }
                        ]
                    }
                ],
                isShowRecruit:false,
                versions: [],
                selectVersionCode: '',
                currentVersionNum: ''
            }
        },
        computed: {
            ...mapState(['versionData', 'isFineshed', 'versionFineshed', 'currentVersionCode', 'versionData','value']),
        },
        watch: {
            '$route': {
                immediate: true,
                deep: true,
                handler: function (n) {
                    console.log(n);
                    
                    this.current = n.meta.index
                    console.log(11111,this.current);
                }
            },
            vdialogVisible(n, o) {
                if (!n) {
                    this.selectVersionCode = JSON.parse(localStorage.getItem('versionCode'))
                }
            }
        },
        created() {
            this.$localData('set', 'environment', this.$route.query.environment)
            this.$localData('set', 'operatingEnv', this.$route.query.env);

            const token = ((window.location.href.split('token=')[1]&&window.location.href.split('token=')[1].split('#')[0])&&(window.location.href.split('token=')[1]&&window.location.href.split('token=')[1].split('#')[0]).split('&')[0])||localStorage.getItem('token')
            this.wx = (window.location.href.split('wx=')[1]&&window.location.href.split('wx=')[1].split('#')[0]).split('&')[0]
            this.recruit = (window.location.href.split('recruit=')[1]&&window.location.href.split('recruit=')[1].split('#')[0])
            if (token) {
                localStorage.setItem('token',token)
            }
            const pageName = window.location.pathname.split('/')[2];
            this.$store.dispatch('getQueryValue',this.researchCode)
            this.isShowRecruit = this.recruit==0?false:true
            // switch (pageName) {
            //     case 'recruitment':
            //         this.current = 0;
            //         break;
            //     case 'view':
            //         this.current = 1;
            //         break;
            //     case 'detail':
            //         this.current = 2;
            //         break;
            //     case 'query':
            //         this.current = 3;
            //         break;
            //     case 'trace':
            //         this.current = 4;
            //         break;
            //     case 'excelTask':
            //         this.current = 5;
            //         break;
            //     default:
            //         break;
            // }

        },
        methods: {
            ...mapMutations(['setVersionFineshed']),
            ...mapActions(['getLastVersionCode']),
            onChangeVersion() {
                this.vdialogVisible = true
            },
            formatterLabel(label){
                if (this.isShowRecruit) {
                    return true
                }else {
                    return label != '招募中心'
                }
            },
            onGoBack() {
                console.log(this.env)
                if (this.env == 'test') {
                    this.$router.go(-1)
                } else {
                    window.location.replace(this.$gconst.testServerUrl)
                }
            },
            onChangeRoute(item) {
                this.$route.push({
                    path: item.to,
                    query: {
                        researchCode: this.researchCode,
                        researchName: this.researchName,
                        environment: this.environment,
                        env: this.env
                    }
                })
            },
            onSelectVersion() {
                this.vdialogVisible = false
                localStorage.setItem('versionCode', JSON.stringify(this.selectVersionCode))
                console.log(this.selectVersionCode);
                this.setVersionFineshed(!this.versionFineshed)
                // this.setCurrentVersionNum(this.versionData.versionList.filter((e) => {
                //     return e.versionCode === this.selectVersionCode
                // })[0].versionNum)
                this.currentVersionNum = this.versionData.versionList.filter((e) => {
                    return e.versionCode == this.selectVersionCode
                })[0].versionNum
            },
            initMenuBtnCode() {
                let pram = {
                    code: this.researchCode,
                    versionCode: JSON.parse(localStorage.getItem('versionCode')),
                    environment: this.env == 'test' ? '1' : '2',
                }
                getMenuByResearch(pram).then(res => {
                    if (res.code == 200 && res.data) {
                        this.$authorContainer.codeContainer = res.data.listFuncCode ? res.data.listFuncCode : []
                        this.$authorContainer.roleId = res.data.roleId ? res.data.roleId : ''
                        localStorage.setItem('user', this.$authorContainer.roleId || '')
                        localStorage.setItem('codeContainer', JSON.stringify(res.data.listFuncCode ? res.data.listFuncCode : []))
                        Vue.prototype.$user = localStorage.getItem('user') || ''
                        this.$authorContainer.environment = this.$localData('get', 'environment')
                        console.log("-------this.$authorContainer-----" + JSON.stringify(this.$authorContainer))
                    }
                }).catch(error => {
                    console.error(error)
                });
            },
            backToResearch() {
                // window.location.href = globalConst.testServerUrl
                this.$router.go(-1);
            },
            addClass(index) {
                this.current = index;
            },
            listItemtemClick(item) {
                console.log('点击链接',JSON.stringify(item) );
                if (item.value == 2 && item.label=="导出数据集" ) {
                    this.title = "导出数据集"
                    this.info = "您确定要导出该研究下的数据集吗？导出进度请到任务列表查看!"
                    this.dialogVisible = true
                } else {
                    
                    this.$router.push({
                        name: item.value,
                        query: item.query
                            ? item.query
                            : {
                                researchCode: this.researchCode,
                                researchName: this.researchName
                            }
                    })
                }
            },
            addExportExcelTask() {
                const researchCode = this.researchCode
                const versionCode=JSON.parse(localStorage.getItem('versionCode'))
                addExportTask(researchCode,versionCode).then(res => {
                    // console.log("----addExportTask----"+JSON.stringify(res))
                    this.$message({
                        type: "success",
                        message: res.data
                    });
                    this.dialogVisible = false
                }).catch(error => {
                    this.dialogVisible = false
                });
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                
                console.log('--------------====-==', vm);
                if (from.name == 'Research' || vm.env != 'test') {
                    vm.getLastVersionCode({
                        v: {
                            researchCode: vm.$route.query.researchCode,
                            publishEnv: vm.env == 'test' ? 1 : 2,
                        },
                        cb: () => {
                            localStorage.setItem('environmentName', JSON.stringify(vm.env == 'test' ? '测试环境' : '生产环境'))
                            vm.envname = JSON.parse(localStorage.getItem('environmentName'))
                            vm.initMenuBtnCode()
                            vm.selectVersionCode = JSON.parse(localStorage.getItem('versionCode'))
                            vm.currentVersionNum = vm.versionData.versionList.filter((e) => {
                                return e.versionCode == vm.selectVersionCode
                            })[0].versionNum
                        }
                    })
                } else {
                    localStorage.setItem('environmentName', JSON.stringify(vm.env == 'test' ? '测试环境' : '生产环境'))
                    vm.envname = JSON.parse(localStorage.getItem('environmentName'))
                    vm.initMenuBtnCode()
                    vm.selectVersionCode = JSON.parse(localStorage.getItem('versionCode'))
                    vm.currentVersionNum = vm.versionData.versionList.filter((e) => {
                        return e.versionCode == vm.selectVersionCode
                    })[0].versionNum
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    #testee {
        height: 100%;

        .testee-menu {
            margin-right: 16px;

            & > li {
                display: inline-block;
                height: 20px;
                line-height: 20px;
                margin: 0 10px;
                color: #000 !important;

                // border-right: 1px solid #fff;
                a {
                    color: #000 !important;
                }
            }
        }

        .testee-header {
            display: inline-block;
            max-width: calc(100% - 420px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: top;
        }

        .testee-menu_focu {
            border-bottom: 2px solid #000;
            padding-bottom: 9px !important;
            border-radius: 1px;
        }
    }

    .header-sub-title,
    .el-tag {
        margin-left: 30px;
    }

    .h-sub-wrap {
        // position: absolute;
        // left: 260px;
        // top: 50px;
        cursor: pointer;

        i {
            margin-left: 4px;
        }
    }

    .dia-v-wrap /deep/ .el-radio-group {
        display: flex;
        flex-direction: column;

        // .dia-v-span {
        //     margin-left: 20px;
        // }
        /deep/ .el-radio__label {
            flex: 1;
            line-height: 50px;
        }
        /deep/ .el-radio {
            margin-right: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #EBEEF5;
        }
    }
    .dia-v-wrap {
        overflow: hidden;
        /deep/ .el-radio__label {
                width: 100%;
                box-sizing: border-box;
            }
        .db-row-wrap {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            overflow: hidden;
            span{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                box-sizing: border-box;
            }
            
        }
    }
    
</style>
<style lang="scss">
    .morePopper {
        // background: rgb(231, 231, 231);
        border-radius: 2px;
        margin-top: 6px !important;
        width: 300px !important;

        .more-title {
            // background: #0D83D8;
            color: #0976C4;
            border-left: solid 4px #0976C4;
            padding-left: 8px;
            user-select: none;
        }

        ul {
            padding: 4px 0 16px;
        }

        li {
            display: inline-block;
            font-size: 12px;
            width: 50%;
            cursor: pointer;
            margin: 0px 0;
        }

        .el-icon-house {
            margin: 0 12px;
            transform: rotate(90deg);
        }
    }
    .new-h-l-wrap {
        flex: 1;
        display: flex;
        align-items: center;
        // position: absolute;
        // left: 20px;
        // top: 30px;
        // bottom: 0;
    }
</style>
